<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <Confirm ref="confirm" :method="store"/>
      <transition name="slide">
        <form ref="frmNuevo" novalidate>
          <CCard class="border-warning">
            <CCardHeader>
              <strong class="text-primary">
                Registro de Docente nuevo
              </strong>
              <div class="card-header-actions">
                <button @click="$refs.modalVideo.mostrarVideo('video-alumno-nuevo.mp477777777777777777')"
                        class="btn btn-outline-info" title="Ver video de ayuda">
                  <font-awesome-icon icon="fas fa-question-circle" />
                  Ayuda
                </button>
              </div>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput label="1er Apellido" type="text" v-model="docente.apellido_paterno" required="required"
                          @change="docente.apellido_paterno=docente.apellido_paterno.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput label="2do Apellido" type="text" v-model="docente.apellido_materno"
                          @change="docente.apellido_materno=docente.apellido_materno.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput label="Nombre(s)" type="text" v-model="docente.nombres" required="required"
                          @change="docente.nombres=docente.nombres.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CSelect
                      :options="generos"
                      :plain="true"
                      :value.sync="docente.genero_id"
                      label="Género"
                      required="required"
                  >
                  </CSelect>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput v-model="docente.documento_identificacion"
                          label="N° de CI/Pasaporte" type="text" required="required"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="docente.expedido"
                          label="Expedido" type="text" required="required"
                          @change="docente.expedido=docente.expedido.toUpperCase()"></CInput>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput v-model="docente.direccion" label="Dirección" type="text" required="required"
                          @change="docente.direccion=docente.direccion.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="docente.celular" label="Celular"
                          type="text" required="required"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="docente.telefono" label="Teléfono"
                          type="text"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="docente.email" label="email" type="email"></CInput>
                </div>
              </div>
            </CCardBody>
            <CCardFooter>
              <CButton class="mr-2" color="primary" @click="store()">Grabar</CButton>
              <CButton color="danger" @click="goBack">Regresar a la pantalla anterior</CButton>
            </CCardFooter>
          </CCard>
        </form>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalVideo from "@/views/notifications/ModalVideo";
import Confirm from "@/views/notifications/Confirm";

var moment = require('moment');
var modalPdf = null;

export default {
  name: 'EditUser',
  components: {Confirm, ModalVideo, Alerts, Toast},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      docente: {
        sede_id: localStorage.sedeSeleccionada,
        usuario_docente_id:0, 
        nombres:'', apellido_paterno:'',
        apellido_materno:'', 
        genero_id:0, 
        documento_identificacion:'', 
        expedido:'', 
        direccion:'', 
        celular:'',
        telefono:'',
      },
      generos: [],
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    store() {
      if (!modalPdf.$refs.frmNuevo.checkValidity()) {
        modalPdf.$refs.frmNuevo.classList.add('was-validated');
      } else {
        modalPdf.$refs.alert.show('Descargando documento');
        axios.post(this.$apiAdress + '/api/docente/store?token=' + localStorage.getItem("api_token"),
            modalPdf.docente
        )
            .then(function (response) {
              modalPdf.$refs.alert.hide();
              modalPdf.docente = {
                id:0,
                sede_id: localStorage.sedeSeleccionada,
                usuario_docente_id:0,
                nombres:'', apellido_paterno:'',
                apellido_materno:'',
                genero_id:0,
                documento_identificacion:'',
                expedido:'',
                direccion:'',
                celular:'',
                telefono:'',
              };
              modalPdf.$refs.mensajeToast.makeToast('', 'Registro creado satisfactoriamente.', 'success');
              modalPdf.$router.push({path: '/docente/'});
            })
            .catch(function (error) {
              modalPdf.$refs.alert.hide();
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
  },
  computed: {
  },
  mounted: function () {
    modalPdf = this;
    modalPdf.docente.sede_id = localStorage.sedeSeleccionada;
    axios.get(this.$apiAdress + '/api/inscripcion/' + localStorage.sedeSeleccionada + '/create?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.generos = valorPorDefecto.concat(response.data.generos);
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}

</script>
<style>